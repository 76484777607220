<template>
  <modal :adaptive="true" :min-height="height" name="my-first-modal" style="background: #00000055;">
    <v-container class="modal-content">
      <v-row class="modalRow">
        <v-col cols="12" md="8">
          <div class="tt-modal-product">
            <h2 class="tt-title titleModal">
              <img v-if="iconPath!=='data: image/png;base64,'" :alt="product.id" :src="iconPath" width="25" class="mr-2"/><br>
              {{ product.name }}
            </h2>
            <p class="prodDesc mt-1">{{ product.description }}</p>
            <a class="tt-cart-total" href="#">
              <div class="tt-total" style="">TOTAL:
                <span class="tt-price" style="color: #dc902a !important;" v-if="price!=null">{{ parseFloat(price).toFixed(2) }}€</span>
              </div>
            </a>
          </div>
          <div class="tt-product-total">
            <div class="tt-total totalModal">
              <p v-if="product.compTypus===1"  class="extraTitle">
                Vols afegir-hi algun extra?
              </p>
              <p v-if="product.compTypus===2"  class="extraTitle">
                Vols afegir-hi alguna salsa?
              </p>
              <p v-if="product.compTypus===3"  class="extraTitle">
                Selecciona una opció
              </p>
              <div class="extrasList">
                <p v-for="complement in product.complements" v-bind:key="complement.coid"
                   style="display: flex;margin:0 0 0 0 !important;width: 25vw">
                  <v-checkbox class="extracheck" color="orange" dark
                              v-on:click="updateExtra(complement.id, complement.name,complement.price)"/>
                  <span style="position: relative;top: -1.75vh;">{{ getComplement(complement) }}
                    <v-tooltip top v-if="complement.description">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mt-n1"
                            color="orange"
                            dark
                            v-bind="attrs"
                            v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{complement.description}}</span>
                    </v-tooltip>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <img :alt="product.name" :src="image" height="150" style="width: 100%"/>

          <button class="btn addToCart modalBT firstModalBT" v-on:click="addToCart(product)">Afegir a la Cistella</button>
          <router-link class="btn btn-border modalBT" to="/resumen">Finalitzar Comanda</router-link>
          <a class="btn btn-border btn-close-popup modalBT" href="#" v-on:click="hideModal">Tornar Enrere</a>

        </v-col>
      </v-row>
    </v-container>
  </modal>
</template>

<script>
export default {
  name: "productModal",
  props: ['product', 'catimg', 'price', 'updateExtra', 'addToCart', 'hideModal'],
  data() {
    return {
      ext1: false,
      ext2: false,
      ext3: false,
      ext4: false,
      ext5: false,
      height: 360,
      image: null,
      iconPath:null
    }
  },
  mounted() {
    this.getImage(this.product.id)
    this.getIcon(this.product.id)
  },
  updated() {
    this.getImage(this.product.id);
    this.getIcon(this.product.id)
  },
  methods: {
    getComplement(complement) {
      if (complement.price == '0.00')
        return complement.name;
      else
        return complement.name + ' +' + complement.price + '€';
    },
    getImage(prodid) {
      const url = this.base_url + '/client/getprodimage/' + this.restid + '/' + prodid;
      this.axios.get(url).then(response => {
        if (response.data.image !== undefined)
          this.image = 'data: image/png;base64,' + response.data.image
      }).catch(() => {
        this.image = 'data: image/png;base64,' + this.catimg
      })
    },
    getIcon(prodid) {
      const url = this.base_url + '/client/getprodicon/' + this.restid + '/' + prodid;
      this.axios.get(url).then(response => {
        if (response.data.icon !== undefined)
          this.iconPath = 'data: image/png;base64,' + response.data.icon
        else this.iconPath = null;
      }).catch(()=>{
        this.iconPath = null;
      })
    }
  }
}
</script>

<style scoped>
.modal-content {
  background: #dc902a;
  padding: 0.25em;
  height: 360px;
}

.modalRow {
  background: black none repeat scroll 0% 0%;
  margin: 0em;
  height: 350px;
}

.titleModal {
  font-family: 'Oswald', sans-serif;
  font-size: 18pt !important;
  font-weight: bold !important;
  color: #dc902a;
  display: block ruby;
}

.prodDesc {
  font-family: 'Poiret One', display;
  font-size: 16pt;
  color: #dc902a !important;
  margin-bottom: 5px;
  margin-top: -5px !important;
}

.totalModal {
  font-family: 'Poiret One', display !important;
  font-size: 14pt !important;
  font-weight: bold !important;
  color: #dc902a !important;
}

.extraTitle {
  font-family: 'Oswald', sans-serif;
  margin: .5em 0 1em 0 !important;
}

.extrasList {
  height: 205px;
  overflow: auto;
  padding-left: .5em;
  padding-top: .75em;
  margin-top: 1em;
}

.v-input {
  margin-top: -1em;
}

.addToCart {
  color: #FFF !important;
}


.tt-cart-total {
  font-family: 'Oswald', sans-serif;
  color: #dc902a;
  font-size: 14pt;
  letter-spacing: 2px;
}

.modalBT {
  width: 90%;
  margin: 0.5em 1em 0.5em 1em;
}

.productInfo {
  padding-left: 2em !important;
}

.modalProductOpts {
  right: -0.25em;
  height: 365px;
  padding-top: 1em;
}

.firstModalBT {
  margin-top: 2em;
}


@media only screen and (max-width: 600px) {
  .modal-content {
    height: 400px;
  }

  .modalRow {
    height: 395px;
  }

  .extrasList {
    height: 22vh;
    margin-bottom: 1em !important;
  }

}
</style>
<style>
.extracheck .theme--dark.v-icon {
  color: #dc902a;
}
</style>