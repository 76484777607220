<template>
  <div class="container-indent nomargin .d-none .d-sm-none .d-md-flex">
    <div class="tt-mainpromo">
      <div class="tt-description">
        <div class="container" style="max-width:100% !important;">
          <div class="tt-bg-img" style="opacity: 0.4;">
            <img alt="" class="tt-bg-img zoomIn animated" src="../../assets/background.jpg"
                 style="height: 30em;width: 100%;">
          </div>
          <div class="tt-layout-box hatch">
            <div class="tt-text-large"
                 style="font-size: 60pt;text-transform: uppercase; font-family: 'Oswald', sans-serif">La Nostra Carta
            </div>
            <div class="tt-text-small" style="font-size:50pt;font-family: 'Poiret One', display">Gaudir del menjar és
              gaudir de la vida
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bannerHome"
}
</script>

<style scoped>
@media (max-width: 575px) {
  .tt-bg-img {
    height: 20em !important;
  }

  .tt-layout-box {
    top: -9em !important;
  }
}
</style>