<template>
  <div class="container-indent">
    <div class="container-fluid">
      <product-modal v-if="product!=null && !isMobile()" :add-to-cart="addToCart" :hide-modal="hideModal" :price="price"
                     :product="product" :catimg="catimg"
                     :update-extra="updateExtra" :is-mobile="isMobile"/>
      <mobile-product-modal v-if="product!=null && isMobile()" :add-to-cart="addToCart" :hide-modal="hideModal"
                            :price="price"
                            :product="product" :catimg="catimg"
                            :update-extra="updateExtra" :is-mobile="isMobile"/>
      <skeleton v-if="!loaded"/>
      <div v-if="loaded" class="tt-block-title menuBlock">
        <h1 class="menuTitle">La Carta</h1>
        <v-chip-group
            v-model="selected"
            :center-active=true
            active-class="activeCat"
            class="menuChips"
            column>
          <v-row>
            <v-col v-for="(category, index) in categories" v-bind:key="category.id" class="chipCol" cols="12" md="2">
              <v-chip v-on:click="setActiveTab(category.id,index)">
                <h2 class="tt-title">{{ category.name }}</h2>
              </v-chip>
            </v-col>
          </v-row>
        </v-chip-group>
      </div>
      <div v-if="loaded" style="overflow-x: auto; margin-bottom: 3em">
        <div v-if="categories[categoryPos].subfamilies.length===0">
          <category-image :type=1 :cid="categories[categoryPos].id"/>
          <h1 class="titleCatMenu">{{ categories[categoryPos].name }}</h1>
          <table class="tableMenu">
            <tbody>
            <tr v-for="product in getCategoryProducts(categoryID)" v-bind:key="product.id">
              <menu-item :product="product" :show-options="showOptions" :is-mobile="isMobile()"/>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <div v-for="cat in categories[categoryPos].subfamilies" v-bind:key="cat.id">
            <category-image :type="1" :cid="cat.id" v-if="isHideTitle(categoryID,cat.id)!==1"/>
            <h1 class="titleCatMenu" v-if="isHideTitle(categoryID,cat.id)===2">
              {{ cat.name }}
            </h1>
            <h1 class="titleCatMenu" v-if="isHideTitle(categoryID,cat.id)===0">
              {{ categories[categoryPos].name }}
              <span class="titlesubCat">{{ cat.name }}</span>
            </h1>
            <category-image :type=2 v-if="isHideTitle(categoryID,cat.id)===1" :cid="cat.id"/>


            <table class="tableMenu">
              <tbody>
              <tr v-for="product in getCategoryProducts(cat.id)" v-bind:key="product.id">
                <menu-item :product="product" :show-options="showOptions" :is-mobile="isMobile()"/>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <img alt="miogroup" class="footerHome" src="../../assets/iconMio.png"
           style="margin: -3em auto 3em auto;display: block;"/>
    </div>
  </div>
</template>

<script>
import ProductModal from "@/components/home/_partials/productModal";
import Skeleton from "@/components/home/_partials/skeleton";
import MenuItem from "@/components/home/menuItem";
import MobileProductModal from "@/components/home/_partials/mobileProductModal";
import CategoryImage from "./categoryImage";

export default {
  name: "menuHome",
  components: {CategoryImage, MobileProductModal, MenuItem, ProductModal, Skeleton},
  props: ['cartProducts'],
  data() {
    return {
      categories: [],
      products: [],
      selectedTab: 1,
      categoryID: 1,
      categoryPos: 0,
      subcategories: [],
      extras: [],
      loaded: false,
      status: 0,
      model: 0,
      selected: 0,
      product: null,
      catimg: null,
      price: 0,
      ext1: false,
      ext2: false,
      ext3: false,
      ext4: false,
      ext5: false,
      cart: this.cartProducts
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    cart: function (val) {
      this.$emit('change', val);
    },
  },
  methods: {
    setActiveTab(tabID, pos) {
      this.selectedTab = tabID
      this.categoryID = tabID
      this.categoryPos = pos
    },
    isHideTitle(cid, subcid) {
      const completeHidenCids = ['60cf2e712a06da039a24df1b', '60cf2ea02a06da039a24df21', '60cf2eba2a06da039a24df27', '60d4c37922341fbed538def9'];
      const onlyTitleCids = ['60cf2e4d2a06da039a24df13', '60d4c28e26337bbea44f50b5']
      let hidecomplet = false;
      let onlytitle = false;
      completeHidenCids.forEach(cids => {
        if (cid.toString() === cids.toString() || subcid === cids) hidecomplet = true;
      })
      onlyTitleCids.forEach(tcid => {
        if (cid.toString() === tcid.toString() || subcid === tcid) onlytitle = true;
      })

      if (hidecomplet) return 1
      else if (onlytitle) return 2
      else return 0
    },
    getData() {
      const url = this.base_url + '/client/getdeliverymenu/' + this.restid;
      this.axios.get(url).then(response => {
        /*this.$ma.trackEvent({
          category: 'Home',
          action: 'Obtener menú',
          properties: {version: this.version}
        });*/
        this.categories = response.data.families;
        this.products = response.data.products;
        console.log(this.products)
        this.selectedTab = response.data.families[0].id;
        this.categoryID = response.data.families[0].id;
        this.loaded = true;
      }).catch(() => {
        /*this.$ma.trackEvent({
          category: 'Home',
          action: 'Error obtener menú',
          properties: {version: this.version, error: e.response.data}
        });*/
      });
    },
    getCategoryProducts(cid) {
      let catprods = [];
      let productKeys = Object.keys(this.products);
  
  // Iterar sobre las claves
  for(let key of productKeys) {
    let products = this.products[key];
    // Verificar si hay productos con la clave deseada
    if (key === cid) {
      catprods = products;
      break; // Si se encuentra la categoría, se puede salir del bucle
    }
  }
  return catprods;
      
    },
    getCategoryImg(cid) {
      const url = this.base_url + '/client/getfamilyimage/' + this.restid + '/' + cid;
      this.axios.get(url).then(response => {
        this.catimg= response.data.image
      }).catch(()=>this.catimg = '')
    },
    showOptions(pid, cid) {
      this.getCategoryProducts(cid).forEach(prod => {
        if (prod.id === pid)
          this.product = prod;
      })
       this.getCategoryImg(cid);
      this.extras = [];
      this.price = this.product.price;
      this.$modal.show('my-first-modal');
    },
    updateExtra(coid, name, price) {
      let pos = 0;
      let found = false;
      this.extras.forEach(extra => {
        if (coid === extra.coid) {
          this.price = parseFloat(this.price) - parseFloat(price);
          this.extras.splice(pos)
          found = true;
        }
        pos++;
      })
      if (!found) {
        this.price = parseFloat(this.price) + parseFloat(price);
        this.extras.push({coid: coid, name: name, price: price});
      }
    },
    setNewCart(id) {
      return new Promise(resolve => {
        let url = this.base_url + '/client/setorder'
        this.axios.post(url, {
          restID: this.restid,
          session: id,
          typus: this.$session.get('selected')
        }).then(response => {
          this.$session.set('CID', response.data.orderID);
          resolve()
        })
      })
    },
    addToCart(product) {
      this.loading = true
      if (this.$session.get('CID') == null) {
        this.setNewCart(this.$session.id()).then(() => {
          let url = this.base_url + '/client/additem'
          this.axios.post(url, {
            restID: this.restid,
            orderID: this.$session.get('CID'),
            productID: product.id,
            complements: this.extras
          }).then(() => {
            this.snackbar = true
            this.loading = false
            let pos = this.cart.length + 1;
            let itemCart = {item: pos, product: this.product, price: this.price, extras: this.extras};
            this.cart.push(itemCart);
            this.hideModal();
            /*this.$ma.trackEvent({
              category: 'Home',
              action: 'añade a carrito',
              properties: {version: this.version, pid: product.id}
            });*/
          }).catch(() => {
            this.loading = false
            this.snackerror = true
            this.hideModal();
            /*this.$ma.trackEvent({
              category: 'Home',
              action: 'error añadir a carrito',
              properties: {version: this.version, error: e.response.data}
            });*/
          })
        }).catch(() => {
          this.loading = false
          this.snackerror = true
          this.hideModal();
          /*this.$ma.trackEvent({
            category: 'Home',
            action: 'error añadir a carrito',
            properties: {version: this.version, error: e.response.data}
          });*/
        });
      } else {
        let url = this.base_url + '/client/additem'
        this.axios.post(url, {
          restID: this.restid,
          orderID: this.$session.get('CID'),
          productID: product.id,
          complements: this.extras
        }).then(() => {
          this.snackbar = true
          this.loading = false
          let pos = this.cart.length + 1;
          let itemCart = {item: pos, product: this.product, price: this.price, extras: this.extras};
          this.cart.push(itemCart);
          this.hideModal();
          /*this.$ma.trackEvent({
            category: 'Home',
            action: 'añade a carrito',
            properties: {version: this.version, pid: product.id}
          });*/
        }).catch(() => {
          this.loading = false
          this.snackerror = true
          this.hideModal();
          /*this.$ma.trackEvent({
            category: 'Home',
            action: 'error añadir a carrito',
            properties: {version: this.version, error: e.response.data}
          });*/
        })
      }
    },
    hideModal() {
      this.$modal.hide('my-first-modal');
    },
    isMobile: function () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style>
.v-image__image.v-image__image--contain {
  background-size: cover !important;
}
</style>
<style scoped>
.catIMG {
  height: 350px;
}

.menuBlock {
  font-size: 45px;
  margin-top: 0.5em;
  border-top: 1px solid #dc902a;
  border-bottom: 1px solid #dc902a;
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.menuTitle {
  color: #dc902a !important;
  font-size: 20pt;
  font-family: "Poiret One";
  font-weight: bold;
}

.active {
  color: #dc902a;
}

tr td {
  color: white;
  margin: 1em;
  padding: 0 1em 1em 1em;
}

.titleCatMenu {
  font-size: 28pt !important;
  font-weight: bold !important;
  color: #dc902a;
  padding: 4vh 0 0 4vw;
  letter-spacing: 2vw !important;
  text-transform: uppercase;
  text-align: center;
}

.titleImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.titlesubCat {
  font-size: 16pt;
  text-transform: lowercase;
}

.price {
  color: #dc902a;
  font-weight: bold;
  font-size: 14pt;
}

.title-menu {
  font-size: 18pt;
  font-weight: bold;
}

.title-menu i {
  font-size: 11pt;
}

.v-chip {
  color: white !important;
  background: black !important;
  font-weight: bold;
  height: 35px !important;
  display: inline-block;
  font-family: 'Oswald', sans-serif;
}

.v-chip h2:hover {
  color: #dc902a !important;
  font-weight: bold;
}

.v-chip.activeCat h2 {
  color: #dc902a !important;
}

.chipCol {
  margin: 0 auto;
  margin-bottom: 0em !important;
}

.tableMenu {
  border: none;
  margin: 0 auto;
  font-family: 'Poiret One';
  font-size: 14pt;
  line-height: 1.2em;
}

.title-menu {
  color: #dc902a !important;
  font-weight: bold;
  font-size: 16pt !important
}

.title-menu:hover {
  color: #ffffff !important;
  cursor: pointer;
}

.title-menu i:hover {
  color: #dc902a !important;
  cursor: pointer;
}

.prodDesc {
  font-family: 'Poiret One', display;
  margin-top: 0;
  color: #dc902a !important;
}

.tt-title {
  letter-spacing: 5px !important;
  text-transform: uppercase;
  font-size: 12pt !important;
  font-weight: bold !important;
  word-wrap: break-word;
  white-space: initial;
  color: #dc902a;
  padding: 0 0 0 0 !important;
}

@media (min-width: 575px) {
  .menuChips {
    margin: 0 5em 0 5em;
  }

  .menuRow {
    width: 40em;
  }
}

@media (max-width: 575px) {
  .footerHome {
    margin-top: 5em !important;
  }

  .catIMG {
    height: 200px;
  }

  .v-chip h2 {
    font-size: 20pt;
  }

  .chipCol {
    max-width: 4em !important;
    float: left;
  }

  .menuPrice {
    margin-top: -3em;
  }

  .prodDesc {
    width: 90%;
  }
}
</style>