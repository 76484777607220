<template>
  <div class="home">
    <header-component :products="products" :cartitems="cartitems" :cartamount="cartamount" :difference="difference"/>
    <div id="tt-pageContent">
      <banner-home/>
      <menu-option v-if="selected===null" :selected="selected" @change="updateStatus"/>
      <menu-home v-else :cart-products="products" @change=updateProducts />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from "@/components/general/headerComponent";
import BannerHome from "@/components/home/bannerHome";
import MenuHome from "@/components/home/menuHome";
import MenuOption from "@/components/home/menuOption";

export default {
  name: 'Home',
  components: {
    MenuOption,
    MenuHome,
    BannerHome,
    HeaderComponent
  },
  data() {
    return {
      products: [],
      cartitems:[],
      cartamount:[],
      difference:0,
      selected: null
    }
  },
  mounted() {
    this.startSession();
  },
  methods: {
    updateProducts(prods) {
      this.products = prods;
      this.$session.set('products', prods);
    },
    startSession() {
      if (!this.$session.exists())
        this.$session.start();
      else {
        if (this.$session.get('selected') !== undefined)
          this.selected = this.$session.get('selected')
        this.refreshCart();

      }
    },
    updateStatus(status) {
      this.selected = status
      this.$session.set('selected', status);
    },
    showDeliveryZone() {
      this.$modal.show('delivery-modal');
    },
    refreshCart() {
      if (this.$session.get('CID') != null) {
        let url = this.base_url + '/client/getorder/' + this.restid + '/' + this.$session.get('CID')
        this.axios.get(url).then(response => {
          if (response.data.order.confirmed) {
            /*this.$ma.trackEvent({
              category: 'Home',
              action: 'pedido confirmado - reset',
              properties: {cid: this.$session.get('CID'),version:this.version}
            });*/
            this.$session.destroy();
            location.reload();
          }
          response.data.order.products.forEach(prod=>{
            if(prod.complements.lenght===0)
              this.cartitems.push({product:prod, price: prod.product.pvp})
            else{
              let price = prod.product.pvp;
              prod.complements.forEach(comp=>{
                price+= comp.price;
              })
              this.cartitems.push({product:prod, price: price})
            }
          })
          this.cartamount = response.data.order.amount;
          this.difference = (25 - parseFloat(response.data.order.amount)).toFixed(2)
        }).catch(err => {
          if (err.response.data.message === "Order already confirmed") {
            /*this.$ma.trackEvent({
              category: 'Home',
              action: 'pedido confirmado - reset',
              properties: {cid: this.$session.get('CID'),version:this.version}
            });*/
            this.$session.destroy();
            location.reload();
          }
        })
      }
    },
  }
}
</script>
