<template>
  <v-row class="mb-10 menuOptions" dense>
    <v-col cols="12" md="12">
      <div class="tt-layout-box hatch">
        <div class="tt-text-large"
             style="font-size: 40pt;text-transform: uppercase;color:#ffffff;text-align: center;font-family: 'Oswald', sans-serif">
          que prefereixes?
        </div>
      </div>
    </v-col>
    <v-col class="optionCard" cols="12" md="5">
      <v-card class="optionCardItem" height="200" width="350" @mouseleave="hoverTake=false"
              @mouseover="hoverTake=true" v-on:click="setOption('1')">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div style="margin: 0 auto">
            <v-card-title class="headline optionTitle" v-bind:class="{hover:hoverTake}">
              Per Recollir
            </v-card-title>
            <v-card-subtitle class="optionText" v-bind:class="{hover:hoverTake}">Fes la teva comanda i vine tu mateix a
              recollir-lo
            </v-card-subtitle>
          </div>
          <img class="mt-3" src="../../assets/take.png" style="width: 100%;height: 100px;"/>
        </div>
      </v-card>
    </v-col>
    <v-col class="optionCard" cols="12" md="5">
      <v-card class="optionCardItem" height="200" width="350" @mouseleave="hoverDelivery=false"
              @mouseover="hoverDelivery=true" v-on:click="setOption('2')">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div style="margin: 0 auto">
            <v-card-title class="headline optionTitle" v-bind:class="{hover:hoverDelivery}">
              T'ho portem a domicili
            </v-card-title>
            <v-card-subtitle class="optionText" v-bind:class="{hover:hoverDelivery}">
              Fes la teva comanda i te'l portem nosaltres
            </v-card-subtitle>
          </div>
          <img class="mt-3" src="../../assets/deliv.png"  style="width: 100%;height: 100px;"/>

        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "menuOption",
  props: ['selectedProp'],
  data() {
    return {
      hoverDelivery: false,
      hoverTake: false,
      selected: this.selectedProp
    }
  },
  mounted(){
    this.selected = '1'
  },
  methods: {
    setOption(selected) {
      this.selected = selected;
      /*this.$ma.trackEvent({
        category: 'Home',
        action: 'Selección de método',
        properties: {metodo: selected,version:this.version}
      });*/
    }
  },
  watch: {
    selected: function (val) {
      this.$emit('change', val);
    }
  }
}
</script>

<style scoped>
.menuOptions {
  margin-top: -2em !important;
}

.optionCard {
  margin: 0 auto;
}

.optionTitle {
  color: #dc902a !important;
  font-family: 'Oswald', sans-serif !important;
  word-break: keep-all;
}

.optionText {
  color: #dc902a !important;
  font-family: 'Poiret One', display;
  font-size: 14pt;
  word-break: keep-all;

}

.optionCardItem {
  padding: 2em;
  border: 1px solid #dc902a;
  background: black !important;
  margin: 0 auto
}

.optionCardItem:hover {
  border: 1px solid #ffffff;
}

.hover {
  color: #ffffff !important;
}

@media (max-width: 575px) {
  .menuOptions {
    margin-top: -8em !important;
  }
}
</style>