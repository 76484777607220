<template>
  <div class="tt-obj-options obj-move-right tt-position-absolute"><!-- tt-cart -->
    <div class="tt-desctop-parent-cart tt-parent-box">
      <div class="tt-cart tt-dropdown-obj">
        <button class="tt-dropdown-toggle"><i class="icon-f-41"></i> <span
            class="tt-badge-cart" style="font-weight: bold;">{{numItems  }}</span></button>
        <div class="tt-dropdown-menu">
          <div class="tt-mobile-add"><h6 class="tt-title">Cistella</h6>
            <button class="tt-close">Tancar</button>
          </div>
          <div class="tt-dropdown-inner">
            <div class="tt-cart-layout">

              <div v-if="cartitems.length==0" class="tt-cart-empty" href="empty-cart.html">
                <i class="icon-f-39"></i>
                <p>
                  No hi ha productes encara
                </p>
              </div>
              <div v-else class="tt-cart-content">
                <div class="tt-cart-list">
                  <div v-for="prod in cartitems" v-bind:key="prod.iid" class="tt-item">
                    <div class="tt-item-descriptions">
                      <h2 class="tt-title" style="font-weight: bold;font-size: 12pt;color: #000;letter-spacing: 1px;">
                        {{ prod.product.product.name }} </h2>
                      <ul class="tt-add-info">
                        <li v-for="item in prod.product.complements" v-bind:key="item.coid"
                            style="margin: 0 0px 0px 5px !important;font-weight: 500;">Extra: {{ item.name }}
                          +{{ item.price }}€
                        </li>
                      </ul>
                      <div class="tt-quantity" style="font-weight: bold;color:#000;">{{ prod.product.quantity }} X</div>
                      <div class="tt-price" style="margin-left: 0.25em;"> {{ prod.price }}€</div>
                    </div>
                    <div class="tt-item-close" v-on:click="removeItem(prod.product.id)"><a class="tt-btn-close"
                                                                                         href="#"></a>
                    </div>
                  </div>
                </div>
                <div class="tt-cart-total-row">
                  <div class="tt-cart-total-title">SUBTOTAL:</div>
                  <div class="tt-cart-total-price">{{ cartamount.toFixed(2) }}€</div>
                </div>
                <div v-if="cartamount>=20 || isTakeaway" class="tt-cart-btn">
                  <div class="tt-item">
                    <router-link class="btn" to="/resumen">Finalitzar Comanda</router-link>
                  </div>
                </div>
                <div v-else-if="cartamount<20" class="tt-cart-btn">
                  <h4 class="minOrder">
                    La comanda mínima són 20€ {{typus}}{{typus === 'takeaway'}}{{cartamount}}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /tt-cart --><!-- tt-account -->
    <div class="tt-desctop-parent-account tt-parent-box">
      <div class="tt-account tt-dropdown-obj">
        <button class="tt-dropdown-toggle"><i class="icon-f-94"></i></button>
        <div class="tt-dropdown-menu">
          <div class="tt-mobile-add">
            <button class="tt-close">Cerrar</button>
          </div>
          <div class="tt-dropdown-inner">
            <h2 v-if="uname!=null" class="cartName">{{ uname }}</h2>
            <ul>
              <li v-if="token!=null">
                <router-link to="/account"><i class="icon-f-94"></i>Les meves dades</router-link>
              </li>
              <li v-if="token!=null"><a @click="logout"><i class="icon-f-77"></i>Sortir</a></li>
              <li v-if="token==null">
                <router-link to="/login"><i class="icon-f-94"></i>Iniciar Sessió</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div><!-- /tt-account -->
  </div>
</template>

<script>
export default {
  name: "cart",
  props: ['products','cartitemsProp','cartamountProp'],
  data() {
    return {
      caritems:[],
      numItems: 0,
      token: null,
      uname: null,
      typus: null
    }
  },
  watch: {
    products: function () {
      this.refreshCart();
    },
    cartitems:function (){
      this.numItems = 0;
      this.cartitems.forEach(item => {
        this.numItems += parseInt(item.product.quantity);
      })
    }
  },
  mounted() {
    this.startSession();
    if (this.$session.get('selected') != undefined)
      this.typus = this.$session.get('selected');
    this.cartitems = this.cartitemsProp
    this.caramount = this.cartamountProp
  },
  methods: {
    isTakeaway(){
      if (this.$session.get('selected') != undefined){
        this.typus = this.$session.get('selected');
        return (this.typus === 'takeaway');
      }else return false;
    },
    startSession() {
      if (!this.$session.exists())
        this.$session.start();
      else {
        if (this.$session.get('token') != null)
          this.token = this.$session.get('token');
        if (this.$session.get('uname') != null)
          this.uname = this.$session.get('uname');
        this.refreshCart();
      }
    },
    refreshCart() {
      if (this.$session.get('CID') != null) {
        let url = this.base_url + '/client/getorder/' + this.restid + '/' + this.$session.get('CID')
        this.axios.get(url).then(response => {
          this.cartitems = [];
          response.data.order.products.forEach(prod=>{
            if(prod.complements.lenght===0)
              this.cartitems.push({product:prod, price: prod.product.pvp.toFixed(2)})
            else{
              let price = parseFloat(prod.product.pvp);
              prod.complements.forEach(comp=>{
                price+= parseFloat(comp.price);
              })
              this.cartitems.push({product:prod, price: price.toFixed(2)})
            }
          })
          this.cartamount = response.data.order.amount;
          this.difference = (25 - parseFloat(response.data.order.amount)).toFixed(2)
        }).catch(err => {
          if (err.response.data.message === "Order already confirmed") {
            this.clearData()
            this.startSession()
          }
        })
      }
    },
    removeItem(productID) {
      let addUrl = this.base_url + '/client/removeitem';

      this.axios.post(addUrl, {
        restID: this.restid,
        orderID: this.$session.get('CID'),
        itemID: productID
      }).then(() => {
        this.refreshCart();
        /*this.$ma.trackEvent({
          category: 'Home',
          action: 'elimino producto de cesta',
          properties: {cid: this.$session.get('CID'),item: productID, version:this.version}
        });*/
      });
    },
    logout() {
      this.$session.destroy();
      location.reload();
    }
  }
}
</script>

<style scoped>
.cartName {
  font-size: 16pt;
  font-weight: bold;
  color: black;
  margin-bottom: -0.5em;
}

.tt-cart-empty i {
  color: black !important;
}

.tt-cart-empty p {
  font-weight: bold;
}

.tt-cart-list {
  overflow-x: hidden;
  max-height: 350px;
}

.minOrder {
  color: black;
  font-weight: bold;
  font-size: 14pt;
  border: 1px solid #000;
  padding: 0.5em;
  margin-top: 1em;
}
</style>