<template>
  <modal :adaptive="true" :resizable="true" :min-height="510" name="my-first-modal" style="background: #00000055;">
    <v-container class="modal-content">
      <v-row class="modalRow">
        <v-col cols="12" md="12">
          <div class="tt-modal-product">
            <img :alt="product.name" :src="image" height="150" style="width: 100%"/>

            <v-row>
              <v-col cols="8" md="8" style="display: flex">
                <img v-if="iconPath!=='data: image/png;base64,'" :alt="product.id" :src="iconPath" width="25" height="25" class="mr-2 mt-2"/>
                <h2 class="tt-title titleModal">
                  {{ product.name }}
                </h2>
              </v-col>
              <v-col cols="4" md="4">
                <a class="tt-cart-total" href="#" v-if="price!='NaN'">
                  <div class="tt-total mt-1">
                    <span class="tt-price">{{ parseFloat(price).toFixed(2) }}€</span>
                  </div>
                </a>
              </v-col>
            </v-row>
            <p class="prodDesc mt-1">{{ product.description }}</p>
          </div>
          <v-expansion-panels accordion dark class="mt-2">
            <v-expansion-panel>
              <v-expansion-panel-header v-if="product.compTypus===1">Vols afegir-hi algun extra?</v-expansion-panel-header>
              <v-expansion-panel-header v-if="product.compTypus===2">Vols afegir-hi alguna salsa?</v-expansion-panel-header>
              <v-expansion-panel-header v-if="product.compTypus===3">Selecciona una opció</v-expansion-panel-header>
              <v-expansion-panel-content>
                    <v-list>
                      <template>
                        <v-list-item v-for="complement in product.complements" v-bind:key="complement.id">
                          <p style="display: flex;margin:0 0 0 0 !important;">
                            <v-checkbox class="extracheck" color="orange" dark
                                        v-on:click="updateExtra(complement.id, complement.name,complement.price)"/>
                            <span style="position: relative;top: -1.75vh;">{{ getComplement(complement) }}</span>

                          </p>
                        </v-list-item>
                      </template>
                    </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <button class="btn addToCart modalBT firstModalBT" v-on:click="addToCart(product)">Afegir a la Cistella</button>
          <button class="btn btn-border btn-close-popup modalBT" v-on:click="hideModal">Tornar Enrere</button>
        </v-col>
      </v-row>
    </v-container>
  </modal>
</template>

<script>
export default {
  name: "mobileProductModal",
  props: ['product', 'catimg', 'price', 'updateExtra', 'addToCart', 'hideModal'],
  data() {
    return {
      ext1: false,
      ext2: false,
      ext3: false,
      ext4: false,
      ext5: false,
      image: null,
      iconPath: null
    }
  },
  mounted() {
    this.getImage(this.product.id)
    this.getIcon(this.product.id)
  },
  updated() {
    this.getImage(this.product.id);
    this.getIcon(this.product.id)
  },
  methods: {
    getComplement(complement) {
      if (complement.price == '0.00')
        return complement.name;
      else
        return complement.name + ' +' + complement.price + '€';
    },
    getImage(prodid) {
      const url = this.base_url + '/client/getprodimage/' + this.restid + '/' + prodid;
      this.axios.get(url).then(response => {
        if (response.data.image !== undefined)
          this.image = 'data: image/png;base64,' + response.data.image
      }).catch(() => {
        this.image = 'data: image/png;base64,' + this.catimg
      })
    },
    getIcon(prodid) {
      const url = this.base_url + '/client/getprodicon/' + this.restid + '/' + prodid;
      this.axios.get(url).then(response => {
        if (response.data.icon !== undefined)
          this.iconPath = 'data: image/png;base64,' + response.data.icon
        else this.iconPath = null;
      }).catch(() => {
        this.iconPath = null;
      })
    }
  }
}
</script>

<style scoped>
.modal-content {
  background: #dc902a;
  padding: 0.25em;
  height: 510px;
}

.modalRow {
  background: black none repeat scroll 0% 0%;
  margin:1px;
  height: 500px;
}

.titleModal {
  font-family: 'Oswald', sans-serif;
  font-size: 18pt !important;
  font-weight: bold !important;
  color: #dc902a;
}
.tt-price{
  color: #dc902a !important;font-weight: bold;
  font-size: 14pt;
}

.prodDesc {
  font-family: 'Poiret One', display;
  font-size: 16pt;
  color: #dc902a !important;
  margin-bottom: 5px;
  margin-top: -5px !important;
}

.totalModal {
  font-family: 'Poiret One', display !important;
  font-size: 14pt !important;
  font-weight: bold !important;
  color: #dc902a !important;
}

.extraTitle {
  font-family: 'Oswald', sans-serif;
  margin: .5em 0 1em 0 !important;
}

.extrasList {
  height: 160px;
  overflow: auto;
  padding-left: .5em;
  padding-top: .75em;
  margin-top: -1em;
}

.v-input {
  margin-top: -1em;
}

.addToCart {
  color: #FFF !important;
}


.tt-cart-total {
  font-family: 'Oswald', sans-serif;
  color: #dc902a;
  font-size: 14pt;
  letter-spacing: 2px;
  text-align: right;
}

.modalBT {
  width: 90%;
  margin: 0.5em 1em 0.5em 1em;
}

.productInfo {
  padding-left: 2em !important;
}

.modalProductOpts {
  right: -0.25em;
  height: 365px;
  padding-top: 1em;
}

.firstModalBT {
  margin-top: 2em;
}


</style>
<style>
.extracheck .theme--dark.v-icon {
  color: #dc902a;
}
.v-list{
  height:140px;/* or any height you want */
  overflow-y:auto
}
</style>