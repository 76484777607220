<template>
  <header id="tt-header"><!-- tt-mobile menu -->
    <div class="tt-mobile-header">
      <div class="container-fluid">
        <div class="tt-header-row">
          <div class="tt-mobile-parent-cart tt-parent-box"></div><!-- /cart --><!-- account -->
          <div class="tt-mobile-parent-account tt-parent-box"></div><!-- /account -->
        </div>
      </div>
      <div class="container-fluid tt-top-line">
        <div class="row">
          <div class="tt-logo-container"><!-- mobile logo -->
            <router-link class="tt-logo tt-logo-alignment" to="/">
              <img alt="logo movil osolemio" src="../../assets/osolemio.png">
            </router-link><!-- /mobile logo -->
          </div>
        </div>
      </div>
    </div><!-- tt-desktop-header -->
    <div class="tt-desktop-header tt-header-static">
      <div class="tt-color-scheme-03 tt-bg-01">
        <div class="container-fluid">
          <div class="tt-header-holder">
            <div class="tt-obj-logo tt-position-absolute"><!-- logo -->
              <router-link class="tt-logo tt-logo-alignment" to="/">
                <img alt="logo osolemio" src="../../assets/osolemio.png">
              </router-link><!-- /logo -->
            </div>
            <cart :products="products" :cartitems="cartitems" :cartamount="cartamount"/>
          </div>
        </div>
      </div>
    </div><!-- stuck nav -->
    <div id="js-tt-stuck-nav" class="tt-stuck-nav">
      <div class="container-fluid">
        <div class="tt-header-row">
          <div class="tt-stuck-parent-menu"></div>
          <div class="tt-stuck-parent-cart tt-parent-box"></div>
          <div class="tt-stuck-parent-account tt-parent-box"></div>
        </div>
      </div>
    </div>
  </header>

</template>

<script>
import Cart from "@/components/general/cart";

export default {
  name: "headerComponent",
  components: {Cart},
  props: ['products','cartitems','cartamount','difference']
}
</script>

<style scoped>

</style>