<template>
  <v-sheet
      align="center center"
      class="px-3 pt-3 pb-12"
      color="black">
    <v-row>
      <v-col class="skeletonItem" cols="12" md="2">
        <v-skeleton-loader
            ref="skeleton"
            :boilerplate=false
            :light=true
            :tile=false
            class="mx-auto"
            type="chip"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="2">
        <v-skeleton-loader
            ref="skeleton"
            :boilerplate=false
            :light=true
            :tile=false
            class="mx-auto"
            type="chip"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="2">
        <v-skeleton-loader
            ref="skeleton"
            :boilerplate=false
            :light=true
            :tile=false
            class="mx-auto"
            type="chip"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="2">
        <v-skeleton-loader
            ref="skeleton"
            :boilerplate=false
            :light=true
            :tile=false
            class="mx-auto"
            type="chip"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="2">
        <v-skeleton-loader
            ref="skeleton"
            :boilerplate=false
            :light=true
            :tile=false
            class="mx-auto"
            type="chip"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="2">
        <v-skeleton-loader
            ref="skeleton"
            :boilerplate=false
            :light=true
            :tile=false
            class="mx-auto"
            type="chip"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="8">
        <v-boilerplate
            class="mb-6"
            type="list-item-two-line"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="8">
        <v-boilerplate
            class="mb-6"
            type="list-item-two-line"/>
      </v-col>
      <v-col class="skeletonItem" cols="12" md="8">
        <v-boilerplate
            class="mb-6"
            type="list-item-two-line"/>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>

export default {
  name: "skeleton",
  components: {
    VBoilerplate: {
      functional: true,

      render(h, {data, props, children}) {
        return h('v-skeleton-loader', {
          ...data,
          props: {
            boilerplate: false,
            dark: true,
            elevation: 2,
            ...props,
          },
        }, children)
      },
    },
  }
}
</script>

<style scoped>
.skeletonItem {
  margin: 0 auto !important;
}

.v-skeleton-loader__list-item-two-line {
  background: black !important;
}
</style>