import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import VueGlobalVariable from "vue-global-var";
import vuetify from './plugins/vuetify'
import VModal from 'vue-js-modal'
import VueCryptojs from 'vue-cryptojs'

Vue.config.productionTip = false
Vue.use(VueSession);
Vue.use(VueCryptojs);
Vue.use(VueAxios, axios);
Vue.use(VModal)
Vue.use(VueGlobalVariable, {
  globals: {
    base_url: 'https://omioback.grupowapps.es',
    restid:'60bdd996583174a4d291ce64',
    version: '2.0.0'
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
