<template>
  <v-row class="menuRow">
    <v-col cols="12" md="10">
      <td style="border: none;">
        <img v-if="iconPath!=='data: image/png;base64,'"  :src="iconPath" width="25"/>
        <span class="title-menu" v-on:click="showOptions(product.id, product.familyID)">
          {{ product.name }}
          <v-btn v-if="!isMobile" class="mx-2" dark small
                 style="background-color:transparent;border: 1px solid #dc902a;border-radius: 20px;">
            <v-icon dark style="font-size: 15pt;color:#dc902a">fas fa-shopping-cart</v-icon>
          </v-btn>
        </span>
        <br>
        <p class="prodDesc mt-1">{{ product.description }}</p>
      </td>
    </v-col>
    <v-col class="menuPrice" cols="12" md="2">
      <td class="price" style="border: none;" v-if="product.price != 'NaN'">{{ product.price }}€
        <v-btn v-if="isMobile" class="mx-2" dark small
               style="background-color:transparent;border: 1px solid #dc902a;border-radius: 20px;" v-on:click="showOptions(product.id, product.familyID)">
          <v-icon dark style="font-size: 15pt;color:#dc902a">fas fa-shopping-cart</v-icon>
        </v-btn>
      </td>
      <td class="price" style="border: none;" v-else>
        <v-btn v-if="isMobile" class="mx-2" dark small
               style="background-color:transparent;border: 1px solid #dc902a;border-radius: 20px;" v-on:click="showOptions(product.id, product.familyID)">
          <v-icon dark style="font-size: 15pt;color:#dc902a">fas fa-shopping-cart</v-icon>
        </v-btn>
      </td>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "menuItem",
  props: ['product', 'showOptions','isMobile'],
  data() {
    return {
      iconPath: null
    }
  },
  mounted() {
    this.getIcon(this.product.id)
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getIcon(prodid) {
      const url = this.base_url + '/client/getprodicon/' + this.restid + '/' + prodid;
      this.axios.get(url).then(response => {
        if (response.data.icon !== undefined)
          this.iconPath = 'data: image/png;base64,' + response.data.icon
      }).catch(()=>{this.iconPath = ''})
    }
  }
}
</script>

<style scoped>
.active {
  color: #dc902a;
}

tr td {
  color: white;
  margin: 1em;
  padding: 0 1em 1em 1em;
}

.titleCatMenu {
  font-size: 28pt !important;
  font-weight: bold !important;
  color: #dc902a;
  padding: 4vh 0 0 4vw;
  letter-spacing: 2vw !important;
  text-transform: uppercase;
  text-align: center;
}

.titleImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.titlesubCat {
  font-size: 16pt;
  text-transform: lowercase;
}

.price {
  color: #dc902a;
  font-weight: bold;
  font-size: 14pt;
}

.title-menu {
  font-size: 18pt;
  font-weight: bold;

}

.title-menu i {
  font-size: 11pt;
}

.menuBlock {
  font-size: 45px;
  margin-top: 0.5em;
  border-top: 1px solid #dc902a;
  border-bottom: 1px solid #dc902a;
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.menuTitle {
  color: #dc902a !important;
  font-size: 20pt;
  font-family: "Poiret One";
  font-weight: bold;
}
.title-menu {
  color: #dc902a !important;
  font-weight: bold;
  font-size: 16pt !important;
  letter-spacing:5px;
}

.title-menu:hover {
  color: #ffffff !important;
  cursor: pointer;
}

.title-menu i:hover {
  color: #dc902a !important;
  cursor: pointer;
}

.prodDesc {
  font-family: 'Poiret One', display;
  margin-top: 0;
  color: #dc902a !important;
  font-size: 18pt;
  letter-spacing: 1px;

}

.tt-title {
  letter-spacing: 5px !important;
  text-transform: uppercase;
  font-size: 12pt !important;
  font-weight: bold !important;
  word-wrap: break-word;
  white-space: initial;
  color: #dc902a;
}
@media screen and (max-width:768px) {
  .menuPrice{
    margin-top: -2em;
  }
}
</style>