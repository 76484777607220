<template>
  <v-img v-if="type===1" aspect-ratio="1" class="catIMG"
         contain :src="'data: image/png;base64,'+image"/>
  <img v-else  class="mt-3 mb-3" style="display: block; margin: 0 auto"
        :alt="cid"
        :src="'data: image/png;base64,'+image">
</template>

<script>
export default {
  name: "categoryImage",
  props:['cid','type'],
  data(){
    return{
      image:null
    }
  },
  mounted() {
    this.getCategoryImg(this.cid)
  },
  methods:{
    getCategoryImg(cid) {
      const url = this.base_url + '/client/getfamilyimage/' + this.restid + '/' + cid;
      this.axios.get(url).then(response => {
        this.image= response.data.image
      })
    },
  }
}
</script>

<style scoped>

</style>